import { SxStyleProp } from "theme-ui";

export const wrapperStyles = {
  display: "grid",
  gridTemplateRows: ["1fr"],
  bg: ["grayScale.0"],
  color: "grayScale.4",
  gridColumnGap: [4, 6, 7],
  px: [4, 6],
  py: [8, 8]
};

export const columnStyles: SxStyleProp = {
  display: "flex",
  flexDirection: "column",
  gridColumn: ["2/9", "2/12", "2/12", "span 4"],
  mb: [6, 6, 6, 0],
  "&:last-of-type": {
    mb: 0
  },
  h2: {
    fontSize: "1.3rem",
    fontFamily: "monospace",
    lineHeight: "1.5rem",
    wordSpacing: "-0.15em",
    m: 0,
    mb: "1rem"
  },
  p: {
    m: 0
  }
};

